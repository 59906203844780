import React, { useContext } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import Section from "../configurable/Section"
import SectionNoAnim from "../configurable/SectionNoAnim"
import ImageRow from "../configurable/ImageRow"
import Col12 from "../grid/Col12"
import "./blank.scss"
import "./promotions.scss"
import Col9 from "../grid/Col9"
import Col3 from "../grid/Col3"
import "./animation.scss"
import * as ReferIll from "~/vectors/refer.inline.svg"


import * as StringInv from "~/vectors/string_inv.inline.svg"
import * as HouseIllustration from "~/vectors/house-illustration.inline.svg"
import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"
import TickList from "../configurable/TickList"

const Promotions = ({ location }) => {

  return (
    <div className="promotions">
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          Promotions
        </Heading>
      </Hero>
      <Block className="segBlock">
        <Section  >
          <div className="container" >
            <Col12>
              <Heading level={1} >Save even more with our discount codes</Heading>
            </Col12>
            <div className="row">
              <Col12>
                <p className="bold-formatting">
                  Welcome to our promotions page. Here you will see all our current and available voucher codes to ensure you receive only the best pricing for Solar & Battery storage.
                </p>
              </Col12>
            </div>
            <SectionNoAnim>
              <ImageRow
                firstCol={8}
                secondCol={4}
                image={require('../../images/voucher3.svg')}
                content={
                  <>

                    <div className="voucher-ticklist">
                      <TickList>

                        <span >Active code for all customers:</span>
                        <li style={{ color: '3c96c5' }}>

                          2022
                          <button
                            className="copy_btn"
                            onClick={() => {
                              navigator.clipboard.writeText('2022');
                              alert("Code copied!");
                            }}
                            style={{ backgroundColor: "transparent", border: "none", cursor: "pointer", borderRadius: "0px" }}
                          >
                            <img 
                              style={{ borderRadius: "0px" }} 
                              src={require('../../images/new_svg_icons/045-copy.svg')}
                            />
                          </button>

                        </li>
                      </TickList>
                    </div>
                    <div className="voucher-ticklist" style={{ marginTop: '2em' }}>
                      <TickList>
                        <span >Active code for emergency workers:*</span>
                        <li style={{ color: '3c96c5' }}>

                          THANK_YOU_2022
                          <button
                            className="copy_btn"
                            onClick={() => {
                              navigator.clipboard.writeText('THANK_YOU_2022');
                              alert("Code copied!");
                            }}
                            style={{ backgroundColor: "transparent", border: "none", cursor: "pointer", borderRadius: "0px" }}
                          >
                            <img 
                              style={{ borderRadius: "0px" }} 
                              src={require('../../images/new_svg_icons/045-copy.svg')}
                            />
                          </button>

                        </li>
                      </TickList>
                    </div>
                  </>
                }
              />
            </SectionNoAnim>
            <div className="row">
              <Col12>
                <p className="bold-formatting" style={{ fontSize: '.8em' }}>
                  *Once you have used this code, we  will need a copy of your discount services card. To see the ones we accept please visit our <a href="/emergency-workers">Emergency Workers page</a>.
                </p>
              </Col12>
            </div>
          </div>
        </Section>
      </Block>


    </div>
  )
}

export default Promotions
